import { css } from '@emotion/react';
import { useMount, useUnmount } from 'react-use';
import { WithFullscreenButton } from '~/components/WithFullscreenButton';
import { useNoSleepMobile } from '~/hooks/useNoSleepMobile';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { store } from '~/pages/heineken_template/_private/store';
import { component } from '~/utils/component';
export const Charting = component(props => {
    useNoSleepMobile();
    useMount(() => {
        debugAPI.chart4.log(`<Charting /> -> useMount() -> charting.create()`);
        store.charting.create();
    });
    useUnmount(() => {
        debugAPI.chart4.log(`<Charting /> -> useUnmount() -> charting.destroy()`);
        store.charting.destroy();
    });
    return (<WithFullscreenButton>
      <div className={props.className} css={css `
          height: 100%;
          width: 100%;
        `} id={'charting'}/>
    </WithFullscreenButton>);
});
